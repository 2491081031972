import Rest from '@/services/Rest';

/**
 * @typedef {PageListService}
 */
export default class PageListService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'page/list'


}