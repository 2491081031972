<template>
  <div class="config-box">
    <div class="config-header">
      <div>
        <h3>Faça as configurações do seu formulário</h3>
        <p>Edite, remova e adicione itens como preferir.</p>
      </div>
      <BaseButton v-b-toggle.sidebar-config variant="primary"
        >Automações</BaseButton
      >
    </div>
    <div class="config-body">
      <b-form-group class="form-group">
        <div class="form-grid">
          <div>
            <label>Título</label>
            <input
              v-model="titulo"
              class="w-100"
              type="text"
              name="titulo"
              id="titulo"
              placeholder="Identificador interno"
            />
          </div>
          <div>
            <div style="display: flex; align-items: center; justify-content: space-between; gap: 20px">
              <label>Página de agradecimento 
                <span @click="removeThankYouPage()" v-if="hasThankYouPage">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="fill: var(--red); cursor: pointer;" viewBox="0 0 256 256"><path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path></svg>
                </span>
              </label>
              <div class="agradecimento-icons pointer" @click="is_path = !is_path">
                <span>{{ is_path ? 'Link externo' : 'Página do funil' }}</span>
                <svg width="12" height="12" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.34896 11.227C8.73766 11.7466 9.23358 12.1766 9.80306 12.4878C10.3726 12.7989 11.0023 12.9839 11.6496 13.0303C12.2969 13.0766 12.9465 12.9832 13.5546 12.7564C14.1626 12.5296 14.7147 12.1747 15.1735 11.7157L17.8888 9.00042C18.7132 8.1469 19.1693 7.00373 19.159 5.81715C19.1487 4.63057 18.6728 3.49551 17.8337 2.65643C16.9946 1.81736 15.8595 1.34141 14.673 1.3311C13.4864 1.32079 12.3432 1.77694 11.4897 2.60131L9.9329 4.14904M11.9694 9.41677C11.5807 8.89712 11.0848 8.46715 10.5153 8.15601C9.94581 7.84487 9.31606 7.65985 8.66878 7.61349C8.0215 7.56714 7.37182 7.66053 6.7638 7.88734C6.15579 8.11414 5.60366 8.46906 5.14488 8.92801L2.42955 11.6433C1.60519 12.4969 1.14904 13.64 1.15935 14.8266C1.16966 16.0132 1.64561 17.1483 2.48468 17.9873C3.32375 18.8264 4.45882 19.3023 5.6454 19.3127C6.83198 19.323 7.97514 18.8668 8.82867 18.0425L10.3764 16.4947" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>

            <BaseSelect
              watch
              v-if="!is_path"
              :selectModel="selectedPage"
              placeholder="Selecione a página"
              track-by="id"
              trackname="title"
              :array="pages"
              @select="passarPath"
              :multiple="false"
              :searchable="true"
              class="multiselect"
              selectLabel=""
              deselectLabel=""
              noResult="Oops! Nenhum página encontrada."
              noOptions="Pesquise pela página"
              selectedLabel=""
              :loading="loadingPages"
              @search="debouncePages"
              @callback="fetchPages($event)"
              @change="selectedPage = $event"
            >
            </BaseSelect>
            <input
              v-if="is_path"
              class="w-100"
              v-model="path"
              type="text"
              name="path"
              id="path"
            />
          </div>
        </div>
        <div class="form-grid">
          <div>
            <div class="label-flex">
              <label>Opt-in</label>
              <img
                @click="openModal('optin-modal')"
                v-if="
                  selectedOpt &&
                  selectedOpt.value &&
                  selectedOpt.value == 'double'
                "
                class="icon pointer"
                src="@/assets/img/icons/email-lead.svg"
                alt="email"
              />
              <img
                v-else
                class="icon"
                src="@/assets/img/icons/email-form.svg"
                alt="email"
              />
            </div>
            <multiselect
              v-model="selectedOpt"
              class="multiselect"
              label="text"
              track-by="value"
              placeholder="Selecione o opt-in"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="opt"
              :multiple="false"
              :searchable="false"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
            >
            </multiselect>
          </div>
          <div>
            <label>Jornada</label>
            <multiselect
              v-model="selectedJornada"
              class="multiselect"
              label="text"
              track-by="value"
              placeholder="Selecione a jornada"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="jornadas"
              :multiple="false"
              :searchable="false"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
            >
              <span slot="noOptions">Selecione uma jornada</span>
              <span slot="noResult">Oops! Nenhuma jornada encontrada</span>
            </multiselect>
          </div>
        </div>
      </b-form-group>
      <b-form-group class="form-group">
        <div class="card">
          <img
            class="icon-card"
            src="@/assets/img/icons/email-form.svg"
            alt="email"
          />
          <div>
            <label>Receber e-mail</label>
            <div class="card-content">
              <p>
                Você receberá um e-mail informativo quando o lead se cadastrar
                no formulário
              </p>
              <b-form-checkbox
                v-b-tooltip.hover
                title="Receber e-mail quando o lead enviar os dados"
                v-model="recebeEmail"
                name="is_main"
                size="lg"
                switch
                class="switch6"
              >
              </b-form-checkbox>
            </div>

            <b-collapse v-model="recebeEmail">
              <div class="label-flex justify-content-between">
                  <label>Informe o endereço de e-mail</label>
                  <div class="agradecimento-icons pointer" @click="addEmail()">
                    <span>Adicionar endereço de e-mail</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                      <path d="M7.72005 1.40706V12.5929M2 7H13.4401" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
              </div>
              <input
                class="mb-3"
                type="text"
                name="emailInput"
                id="emailInput"
                placeholder="exemplo@email.com"
                v-model="email"
                @keyup.enter="addEmail()"
              />

              <div class="email-notification">
                <div v-for="(email, index) in registeredsEmails" :key="index" class="item">
                  <span>{{ email }}</span> 
                  <span @click="removeEmail(index)" class="remove">
                    x
                  </span>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>

        <div class="card">
          <img
            class="icon-card"
            src="@/assets/img/icons/pipeline-form.svg"
            alt="CRM"
          />
          <div>
            <label>CRM</label>
            <div class="card-content">
              <p>
                Ao responder o formulário o lead será adicionado no CRM com
                a tag selecionada
              </p>
              <b-form-checkbox
                v-b-tooltip.hover
                title="Lead entra no CRM ao se cadastrar"
                v-model="pipelineOpt"
                name="is_main"
                size="lg"
                switch
                class="switch6"
              >
              </b-form-checkbox>
            </div>
            <b-collapse v-model="pipelineOpt">
              <div class="add-link-body">
                <label>CRM que deve ser inserido</label>
                <router-link
                  v-if="!pipelines.length && stop2 && verificUserLevel"
                  :to="{ name: 'CRM' }"
                  class="add-link"
                  >Deseja criar um CRM?</router-link
                >
              </div>
              <BaseSelect
                id="ajax"
                :selectModel="selectedPipeline"
                placeholder="Pesquise o CRM"
                track-by="id"
                trackname="title"
                :array="pipelines"
                :multiple="false"
                :searchable="true"
                class="multiselect"
                selectLabel=""
                direction="top"
                deselectLabel=""
                noResult="Oops! Nenhum CRM encontrado."
                noOptions="Pesquise pelo CRM"
                selectedLabel=""
                v-if="showComponentCrm"
                @search="debounceCrm($event)"
                @callback="fetchPipelines($event)"
                @change="selectedPipe($event)"
              >
              </BaseSelect>

              <label class="mt-3">Tag</label>
              <multiselect
                class="mb-4"
                v-model="board"
                id="ajax"
                label="name"
                track-by="id"
                placeholder="Selecione uma tag"
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                open-direction="top"
                :options="boards"
                :disabled="!selectedPipeline"
                :multiple="false"
                :searchable="true"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="true"
                :show-no-results="true"
                :hide-selected="true"
                :loading="loadingBoards"
              >
                <span slot="noOptions">Pesquise pelo board</span>

                <span slot="noResult">Oops! Nenhum board encontrado.</span>
              </multiselect>
            </b-collapse>
          </div>
        </div>
        <div class="card">
          <img
            class="icon-card"
            src="@/assets/img/icons/plane.svg"
            alt="distribuição"
          />
          <div @click.once="renderSellers = true">
            <label>Distribuição</label>
            <div class="card-content">
              <p>
                Selecione os vendedores que você deseja que receba os leads.
              </p>
              <b-form-checkbox
                v-b-tooltip.hover
                title="Distribuir Leads entre os vendedores selecionados"
                v-model="distribuicaoOpt"
                name="is_main"
                size="lg"
                switch
                class="switch6"
              >
              </b-form-checkbox>
            </div>

            <!-- Pré Vendedor -->
            <b-collapse v-model="distribuicaoOpt">
              <div class="add-link-body">
                <label>Selecione os pré-vendedores</label>
                <router-link
                  v-if="sellers.length === 1 && canShowOption()"
                  :to="{ name: 'Usuarios' }"
                  class="add-link"
                  >Deseja criar um pré-vendedor?</router-link
                >
              </div>
              
              <BaseSelect
                class="fit-height"  
                :selectModel="selectedPreSeller"
                track-by="id"
                trackname="email"
                :array="filteredPreSellers"
                placeholder="Pesquise pelo e-mail"
                specificType="sellers"
                @select="selectPreSeller"
                :multiple="true"
                :searchable="true"
                selectLabel=""
                deselectLabel=""
                direction="top"
                noResult="Nenhum pré-vendedor encontrada."
                noOptions="Nenhum pré-vendedor encontrado."
                selectedLabel=""
                @search="debounceVendedor($event)"
                @callback="fetchSellers($event)"
                @change="selectedPreSeller = $event"
                :watch="true"
              >
              </BaseSelect>

              <div class="d-flex align-items-center justify-content-between">
                <p>Trocar o pré-vendedor ao se cadastrar novamente?</p>
                <div class="card-content">
                  <b-form-checkbox
                    v-b-tooltip.hover
                    title="Distribuir Leads entre os vendedores selecionados"
                    v-model="sellerPreRedistribute"
                    name="is_main"
                    size="lg"
                    switch
                    class="switch6"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <!-- Vendedor  -->
              <div class="add-link-body">
                <label>Selecione os vendedores</label>
                <router-link
                  v-if="sellers.length === 1 && canShowOption()"
                  :to="{ name: 'Usuarios' }"
                  class="add-link"
                  >Deseja criar um vendedor?</router-link
                >
              </div>

              <BaseSelect
                class="fit-height"
                :selectModel="selectedSeller"
                track-by="id"
                trackname="email"
                :array="filteredSellers"
                placeholder="Pesquise pelo e-mail"
                specificType="sellers"
                @select="selectSeller"
                :multiple="true"
                :searchable="true"
                selectLabel=""
                deselectLabel=""
                direction="top"
                noResult="Nenhum vendedor encontrada."
                noOptions="Nenhum vendedor encontrado."
                selectedLabel=""
                @search="debounceVendedor($event)"
                @callback="fetchSellers($event)"
                @change="selectedSeller = $event"
                :watch="true"
              >
              </BaseSelect>

              <div class="d-flex align-items-center justify-content-between mt-4">
                <p>Trocar o vendedor ao se cadastrar novamente?</p>
                <div class="card-content">
                  <b-form-checkbox
                    v-b-tooltip.hover
                    title="Distribuir Leads entre os vendedores selecionados"
                    v-model="sellerRedistribute"
                    name="is_main"
                    size="lg"
                    switch
                    class="switch6"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <p>Sobrepor outras distribuições</p>
                <div class="card-content">
                  <b-form-checkbox
                    v-b-tooltip.hover
                    title="Com esta opção ativa, qualquer outra distribuição será ignorada"
                    v-model="ignoreOthersDistributions"
                    name="is_main"
                    size="lg"
                    switch
                    class="switch6"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </b-collapse>
            
            <div v-if="distribuicaoOpt == false" style="padding: 7px; border: 1px solid #db2; border-radius: 10px; background-color: #db21; margin-top: 5px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#db2" style="display: inline-block; margin-right: 5px;" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg> 
              <span style="color: #db2; display: inline; font-size: 14px;">Importante estar habilitada para automações de mensagens do Greenn Message</span>
            </div><br>
          </div>
        </div>
        <div class="card">
          <img
            class="icon-card"
            src="@/assets/img/icons/recaptcha.svg"
            alt="recaptcha"
          />
          <div>
            <label>reCAPTCHA</label>
            <div class="card-content">
              <p>
                Ative o reCAPTCHA no formulário para aumentar a segurança de
                envios dos dados
              </p>
              <b-form-checkbox
                v-b-tooltip.hover
                title="Ativar recaptcha nesse formulário"
                v-model="recaptchaOpt"
                name="is_main"
                size="lg"
                switch
                class="switch6"
              >
              </b-form-checkbox>
            </div>
            <b-collapse v-model="recaptchaOpt">
              <label>Chave reCAPTCHA</label>
              <input
                type="text"
                name="recaptcha"
                id="recaptcha"
                class="w-100"
                placeholder="Digite a chave do reCAPTCHA"
                v-model="reCAPTCHAKey"
              />
            </b-collapse>
          </div>
        </div>
        <!-- <div class="card">
          <img
            class="icon-card"
            src="@/assets/img/icons/user-check.svg"
            alt="check"
          />
          <div>
            <label>LGPD</label>
            <div class="card-content">
              <p>Formulário para exercício dos direito dos dados pessoais</p>
              <b-form-checkbox
                v-b-tooltip.hover
                title="Formulário para exercício dos direitos de Dados Pessoais"
                v-model="lgpd"
                name="is_main"
                size="lg"
                switch
                class="switch6"
              >
              </b-form-checkbox>
            </div>
          </div>
        </div> -->
      </b-form-group>
    </div>
    <sidebarConfig
      :permission_sidebar="permission"
      :dados="form_metas"
      :form="form_metas"
      @pegarDados="puxarDados"
      v-if="isFormLoaded"
    />
    <optInModal :dados="form_metas" @setOptin="insertOptin" />
  </div>
</template>

<script>
import Multiselect from "../../../node_modules/vue-multiselect";
import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();
import sidebarConfig from "@/components/Formularios/sidebarConfig";
import optInModal from "@/components/Formularios/optInModal";

import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();

import BaseButton from "@/components/BaseButton";

import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();

import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();

import PageService from "@/services/resources/PageService";
const servicePage = PageService.build();

import _ from "lodash";

export default {
  data() {
    return {
      renderSellers: false,
      pageVendedor: 1,
      pageCrm: 1,
      pagePages: 1,
      showComponentCrm: true,
      showComponentVendedor: true,
      searchCrm: "",
      searchVendedor: "",
      searchPages: "",
      loadingVendedor: "",
      loadingPages: false,
      titulo: "",
      pages: [],
      tags: [],
      selectedPage: null,
      opt: [
        { value: "single", text: "Single" },
        { value: "double", text: "Double" },
      ],
      selectedOpt: { value: "single", text: "Single" },
      jornadas: [
        { value: "M", text: "Marketing" },
        { value: "V", text: "Vendas" },
      ],
      selectedJornada: { value: "M", text: "Marketing" },
      recebeEmail: false,
      email: "",
      registeredsEmails: [],
      pipelineOpt: false,
      pipelines: [],
      selectedPipeline: null,
      boards: [],
      board: [],
      selectedSeller: [],
      selectedPreSeller: [],
      distribuicaoOpt: false,
      recaptchaOpt: false,
      recaptchaTema: [
        { value: "light", text: "Claro" },
        { value: "dark", text: "Escuro" },
      ],
      reCAPTCHAKey: "",
      selectedRecaptchaTema: { value: "light", text: "Claro" },
      lgpd: false,
      data: {},
      data_sidebar: {},
      optin_data: {},
      sellers: [],
      path: "/",
      is_path: true,
      stop: false,
      stop2: false,
      stop3: false,
      stopPages: false,
      already_get_all_tags: false,
      sellerRedistribute: false,
      sellerPreRedistribute:false,
      tags: [],
      loadingBoards: false,
      ignoreOthersDistributions: false
    };
  },
  components: {
    Multiselect,
    sidebarConfig,
    BaseButton,
    optInModal,
  },
  props: ["permission", "form_metas", "isFormLoaded"],
  methods: {
    addEmail(){
      var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;
      if(!this.email){
        this.$grToast.toast("Insira um e-mail", {
          title: "Formulário",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      if (!this.email.match(emailRegex)) {
        this.$grToast.toast("Insira um e-mail válido", {
          title: "Formulário",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      if(this.registeredsEmails.includes(this.email)){
        this.$grToast.toast("Esse e-mail já está nas opções", {
          title: "Formulário",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      this.registeredsEmails.push(this.email);
      this.email = "";
    },
    removeEmail(index){
      this.registeredsEmails.splice(index, 1)
    },
    removeThankYouPage() {
      this.selectedPage = undefined
      this.path = '/'
    },
    debounceVendedor: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentVendedor();
      } else {
        this.searchVendedor = query;
        this.pageVendedor = 1;
        this.sellers = [];
        this.stop3 = false;
        this.fetchSellers(this.pageVendedor, query);
      }
    }, 500),
    resetComponentVendedor() {
      this.pageVendedor = 1;
      this.searchVendedor = "";
      this.sellers = [];
      this.stop3 = false;
      this.showComponentVendedor = false;
      this.$nextTick(() => {
        this.showComponentVendedor = true;
      });
    },
    fetchSellers(page = 1, query = "") {
      if (this.stop3) {
        return;
      }

      var data = {
        page: `${page}&order_by=email&order=ASC&search=${query}`,
      };

      serviceSeller
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stop3 = true;
          }
          this.sellers = this.sellers.concat(resp.data);
          this.sellers = this.sellers.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
          this.getMe();
        })
        .catch((err) => {});
    },
    canShowOption() {
      let level = this.$store.getters.user.user.level;
      if (level != "blogger" && level != "seller" && level != "templater") {
        return true;
      }
      return false;
    },
    debouncePages: _.debounce(function (query) {
      this.pagePages = 1;
      this.searchPages = query;
      this.pages = [];
      this.stopPages = false;
      this.fetchPages(this.pagePages, query);
    }, 500),
    fetchPages(page = 1, query = "") {
      if (this.stopPages || this.loadingPages) {
        return;
      }

      this.loadingPages = true;

      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "save",
        order_by: "title",
        order: "ASC",
        search: query,
      };

      PaginaListService.create(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stopPages = true;
          }

          this.pages = this.pages.concat(resp.data);
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingPages = false;
        });
    },
    selectSeller() {
      var sellers = this.selectedSeller;
      var result = sellers.find((x) => x.id === "distribution");
      if (result) {
        this.selectedSeller = [result];
      }
    },
    selectPreSeller() {
      var sellers = this.selectedPreSeller;
      var result = sellers.find((x) => x.id === "distribution");
      if (result) {
        this.selectedPreSeller = [result];
      }
    },
    debounceCrm: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentCrm();
      } else {
        this.searchCrm = query;
        this.pageCrm = 1;
        this.pipelines = [];
        this.stop2 = "";
        this.fetchPipelines(this.pageCrm, query);
      }
    }, 500),
    resetComponentCrm() {
      this.pageCrm = 1;
      this.searchCrm = "";
      this.pipelines = [];
      this.stop2 = "";
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
    },
    fetchPipelines(page = 1, query = "") {
      if (this.stop2) {
        return;
      }

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };

      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));

          if (response.data.length < response.per_page) {
            this.stop2 = true;
          }

          this.pipelines = this.pipelines.concat(pipelines);
        })
        .catch((err) => {
          // this.pipelines = [];
        });
    },
    getMeta(metas, chave) {
      if (!metas.length || !chave) {
        return;
      }
      var result = metas.find((x) => x.meta_key == chave);
      if (!result) {
        return;
      }
      return result.meta_value;
    },
    async selectedPipe(e) {
      this.selectedPipeline = e;
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
      this.board = '',
      this.loadingBoards = true;
      let tags_selecteds = e?.tags ?? [];

      try {
        const tagPromises = tags_selecteds.map(async tagID => {
          let tag = this.tags.find(x => x.id == tagID);
          if (!tag) {
            tag = await this.getTag(tagID);
          }
          return tag;
        });

        const resolvedTags = await Promise.all(tagPromises);
        const boards = resolvedTags.filter(tag => tag !== ''); // Remove tags that couldn't be fetched
        this.boards = boards;
      } finally {
        this.loadingBoards = false;
      }
    },
    getTag(tagID) {
      return new Promise((resolve) => {
        serviceTags
          .read(`/show/${tagID}`)
          .then((resp) => {
            this.tags.push(resp);
            resolve(resp);
          })
          .catch((error) => {
            resolve('');
          });
      });
    },
    retornaStringify(users) {
      var ids = [];
      for (let i = 0; i < this[users].length; i++) {
        const element = this[users][i];
        ids.push(element.id);
      }
      return JSON.stringify(ids);
    },
    insertOptin(data) {
      this.optin_data = data;
    },
    setContetForm() {
      if (!this.$route.name === "EditarFormulario" || !this.form_metas || this.form_metas.failed) {
        return
      }
      this.titulo = this.form_metas.title;

      if (this.getMeta(this.form_metas.metas, "selected_page")) {
        let data = {
          id: `get/${this.getMeta(this.form_metas.metas, "selected_page")}`,
        };
        servicePage
          .read(data)
          .then((resp) => {
            this.selectedPage = resp;
            this.path = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${resp.path_name}`
            this.$nextTick(() => {
              this.is_path = false;
            });
          })
      }
      if (this.getMeta(this.form_metas.metas, "thank_you_page") && this.getMeta(this.form_metas.metas, "thank_you_page") !== '/') {
        this.path = this.getMeta(this.form_metas.metas, "thank_you_page")
      } else {
        this.path = '/'
        this.is_path = false;
      }

      this.selectedOpt = this.opt.find(
        (x) => x.value == this.getMeta(this.form_metas.metas, "type_optin")
      );
      this.selectedJornada = this.jornadas.find(
        (x) =>
          x.value == this.getMeta(this.form_metas.metas, "journey_type")
      );
      if (!this.selectedJornada) {
        this.selectedJornada = { value: "V", text: "Vendas" };
      }
      this.recebeEmail =
        this.getMeta(this.form_metas.metas, "receive_email") === "true"
          ? true
          : false;

      this.sellerRedistribute = this.getMeta(this.form_metas.metas, "redistribute") === "true"
        ? true
        : false;
      this.sellerPreRedistribute = this.getMeta(this.form_metas.metas, "redistribute") === "true"
        ? true
        : false;
      let emailOptions = this.getMeta(this.form_metas.metas, "register_email_lead");
      this.registeredsEmails = !emailOptions ? [] : emailOptions.split(',');

      this.ignoreOthersDistributions = this.getMeta(this.form_metas.metas, "ignore_others_distributions") === "true"
        ? true
        : false;
      this.pipelineOpt =
        this.getMeta(this.form_metas.metas, "add_pipeline") === "true"
          ? true
          : false;
      this.selectedPipeline = this.form_metas.pipeline;
      this.selectedPipe(this.selectedPipeline);
      this.board = this.form_metas.board_id;

      this.recaptchaOpt =
        this.getMeta(this.form_metas.metas, "recaptcha") === "true"
          ? true
          : false;
      this.selectedRecaptchaTema = this.recaptchaTema.find(
        (x) =>
          x.value === this.getMeta(this.form_metas.metas, "recaptcha_theme")
      );

      this.reCAPTCHAKey = this.getMeta(
        this.form_metas.metas,
        "reCAPTCHAKey"
      );

      this.lgpd =
        this.getMeta(this.form_metas.metas, "active_lgpd_form") === "true"
          ? true
          : false;

      
      this.selectedSeller = this.form_metas.users;
      this.selectedPreSeller = this.form_metas.preSellers;

      this.$nextTick(() => {
        this.distribuicaoOpt =
        this.getMeta(this.form_metas.metas, "lead_distribution") == "true"
          ? true
          : false;
        this.renderSellers = true;
      })
    },
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    puxarDados(data) {
      this.data_sidebar = data;
    },
    passarPath(e) {
      this.path = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${e.path_name}`;
    },
  },
  computed: {
    sellersVuex() {
      return this.$store.getters.seller;
    },
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    filteredPreSellers() {
      const filteredPresellers = this.sellers.filter(preSeller => 
        !this.selectedSeller.some(seller => seller.id === preSeller.id)
      );
      return filteredPresellers;
    },
    filteredSellers() {
      const filteredSellers = this.sellers.filter(seller => 
        !this.selectedPreSeller.some(preSeller => preSeller.id === seller.id)
      );
      return filteredSellers;
    },
    hasThankYouPage() {
      if (!this.is_path && (!this.selectedPage || !this.selectedPage.id) ) {
        return false
      }
      if (this.is_path && (!this.path || this.path === '/')) {
        return false
      }
      return true
    },
  },
  created() {
    if (this.form_metas) {
      this.titulo = this.form_metas.title;
    }
  },
  watch: {
    permission(e) {
      setTimeout(() => {
        if (e) {
          let emails = this.registeredsEmails.join(',');
          let data = {
            title: this.titulo,
            metas: {
              receive_email: this.recebeEmail ? "true" : "false",
              register_email_lead: emails,
              type_optin:
                this.selectedOpt && this.selectedOpt.value
                  ? this.selectedOpt.value
                  : "single",
              add_pipeline: this.pipelineOpt ? "true" : "false",
              pipeline_id: this.selectedPipeline
                ? this.selectedPipeline.id
                : "",
              board_id: this.board && this.board.id ? this.board.id : "",
              users_list: this.retornaStringify('selectedSeller'),
              preUsersList: this.retornaStringify('selectedPreSeller'),
              lead_distribution: this.distribuicaoOpt ? "true" : "false",
              selected_page: this.selectedPage ? this.selectedPage.id : null,
              thank_you_page: this.path && this.path.length ? this.path : "/",
              thank_you_page_manual: true,
              recaptcha: this.recaptchaOpt ? "true" : "false",
              recaptcha_theme:
                this.selectedRecaptchaTema && this.selectedRecaptchaTema.value
                  ? this.selectedRecaptchaTema.value
                  : "white",
              active_lgpd_form: this.lgpd ? "true" : "false",
              journey_type: this.selectedJornada
                ? this.selectedJornada.value
                : null,
              reCAPTCHAKey: this.reCAPTCHAKey,
              redistribute: this.sellerRedistribute ? "true" : "false",
              preRedistribute: this.sellerPreRedistribute ? "true" : "false",
              ignore_others_distributions: this.ignoreOthersDistributions ? "true" : "false"
            },
          };
          this.data = data;
          this.data.metas = {
            ...data.metas,
            ...this.data_sidebar,
            ...this.optin_data,
          };
          this.$emit("pegarDados", this.data);
        }
      }, 100);
    },
    form_metas() {
      this.setContetForm();
    },
  },
};
</script>

<style scoped lang="scss">
.config-box {
  max-width: 900px;
  margin: 20px auto;
  padding: 10px 33px;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--gray01);
  }
  .config-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .config-body {
    width: 100%;
    padding: 30px;
    border: 1px solid #ededf0;
    border-radius: 10px;
    margin-bottom: 100px;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #81858e;
  }
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: var(--gray01);
    margin-bottom: 10px !important;
  }
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .form-grid > div:has(.multiselect--active) {
    position: relative;
    z-index: 2;
  }
  .label-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .agradecimento {
    gap: 20px;
  }
  .agradecimento-icons {
    user-select: none;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 5px 8px;
    border: 1px solid var(--greenn);
    border-radius: 10px;
    margin-top: -10px;
    transition: .3s;
    span {
      font-size: 12px;
      color: var(--greenn);
      line-height: 1;
      color: var(--greenn);
      font-weight: 500;
    }
    svg > path {
      stroke: var(--greenn);
    }
    &:hover {
      background-color: var(--greenn2)
    }
  }
  .pointer {
    cursor: pointer;
  }
  .icon {
    width: 16px;
    height: 17px;
    margin-top: -10px;
  }
  .form-group {
    margin-bottom: 30px !important;
    &:last-of-type {
      margin-bottom: 0px !important;
    }
  }
  .card {
    display: grid;
    grid-template-columns: 30px 1fr;
    border: none;
    border-bottom: 1px solid #ededf0;
    height: auto;
    margin-bottom: 30px;
    &:last-of-type {
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
  .icon-card {
    width: 19px;
    height: 19px;
    padding-bottom: 4px;
  }
  .card-top {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .switch6 {
    margin-bottom: 25px;
  }
  #emailInput {
    width: 100%;
  }
  .multiselect--active {
    z-index: 10;
  }
  .email-notification{
    display: flex;
    gap: 15px;
    padding-bottom: 15px;
    flex-wrap: wrap;
    .item{
      position: relative;
      padding: 4px 24px 4px 10px;
      border-radius: 5px;
      line-height: 1;
      background: var(--greenn);
      label, span{
        color: var(--white);
      }
      .remove{
        position: absolute;
        right: 9px;
        color: #266d4d;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.config-box {
  .multiselect__tag{
    padding: 7.5px 35px 7.5px 10px !important;
  }
  .multiselect__tags{
    max-height: 136px !important;
  }
  .multiselect__tag-icon{
    background-color: rgba(247,247,247, 0.2) !important;
    border-radius: 100% !important;
    width: 20px !important;
    height: 20px !important;
    font-weight: 400 !important;
    top: 4px !important;
    right: 5px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
  }
  .multiselect__tag-icon:after{
    color:var(--white-light) !important;
    font-size: 25px !important;
  }
}
</style>
