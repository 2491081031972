<template>
    <div>
        <MakeForm></MakeForm>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import MakeForm from '@/components/Formularios/makeForm';

export default {
  components: {
    BaseView,
    MakeForm
  }
}
</script>